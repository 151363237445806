import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "reload" })
class ReloadModule extends VuexModule {
  private ReloadStatus = false;

  get status(): boolean {
    return this.ReloadStatus;
  }

  @Mutation
  public setReload(state: boolean): void {
    this.ReloadStatus = state;
  }

  @Action
  public showReload(): void {
    this.context.commit("setReload", true);
  }

  @Action
  public hideReload(): void {
    this.context.commit("setReload", false);
  }
}

export default ReloadModule;
