






 
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "./store";
import OverlayModule from "./store/modules/overlay.module";
const overlayModule = getModule(OverlayModule, store); 

@Component
export default class App extends Vue {
  get overlay(): boolean {
    return overlayModule.status;
  } 
}
