import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import AuthModule from "@/store/modules/auth.module";
import store from "@/store";
import { getModule } from "vuex-module-decorators";

const authModule = getModule(AuthModule, store);

const Home = () => import("../views/Home.vue");
const Tondi = () => import("../views/Tondi.vue");
const Piatti = () => import("../views/Piatti.vue");
const Quadri = () => import("../views/Quadri.vue");
const Speciali = () => import("../views/Speciali.vue");
const Esagoni = () => import("../views/Esagoni.vue");

const Login = () => import("../views/LoginPage.vue");
const Layout = () => import("../Layout.vue");


Vue.use(VueRouter);
  


const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/",
    component: Layout,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/tondi",
        name: "Tondi",
        component: Tondi,
      },
      {
        path: "/quadri",
        name: "Quadri",
        component: Quadri,
      },
      {
        path: "/piatti",
        name: "Piatti",
        component: Piatti,
      },
      {
        path: "/esagoni",
        name: "Esagoni",
        component: Esagoni,
      },
      {
        path: "/speciali",
        name: "Speciali",
        component: Speciali,
      }
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  }, 
  {
    name: "logout",
    path: "/logout",
    component: () => { 
      authModule.logout();
      router.push({ path: "/login" });
    },
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(function (to, from, next) {
  const token = authModule.token;
  if (token) {
    if (to.path === "/login") {
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    if (to.path === "/login") {
      next();
    } else {
      next({ path: "/login" });
    }
  }
  next();
});
export default router;
