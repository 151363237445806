import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "overlay" })
class OverlayModule extends VuexModule {
  private overlayStatus = false;

  get status(): boolean {
    return this.overlayStatus;
  }

  @Mutation
  public setOverlay(state: boolean): void {
    this.overlayStatus = state;
  }

  @Action
  public showOverlay(): void {
    this.context.commit("setOverlay", true);
  }

  @Action
  public hideOverlay(): void {
    this.context.commit("setOverlay", false);
  }
}

export default OverlayModule;
