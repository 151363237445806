import Vue from "vue";
import Vuex from "vuex";  
import VuexPersistence from "vuex-persist";
import Cookies from "js-cookie";
import OverlayModule from "@/store/modules/overlay.module";
import AuthModule from "@/store/modules/auth.module";  
import ReloadModule from "@/store/modules/reload.module";
import { AuthState } from "@/models/utils/auth-state.interface";
Vue.use(Vuex);

const vuexCookie = new VuexPersistence({
  restoreState: (key) => Cookies.getJSON(key),
  saveState: (key, state) => {  
    // const element = Object.values(state)[0]; 
    // if((Object.values(element)[0] as AuthState).rememberMe){
    Cookies.set(key, state, {
      expires: 1,
    });
    // }else{
    //   Cookies.set(key,state,{
    //     storage : sessionStorage
    //   });
    // } 
  },
  modules: ["auth"],
});

const store = new Vuex.Store({
  modules: { 
    auth: AuthModule,
    overlay: OverlayModule, 
    reload: ReloadModule
  }, 
  plugins: [vuexCookie.plugin],
});

export default store;
  